:root {
  --c-white: #FFFFFF;
  --c-black: #000;
  --c-blue: #0075ff;
  --c-red: #B3261E;

  --c-text-primary: #0a0a0a;
  --c-text-secondary: #3c3c3c;
  --c-text-tertiary: #878787;
  --c-text-success: #1db034;
  --c-text-danger: #cd2f26;
  --c-text-warning: #ff9500;

  --c-gray-my: #F0F0F0;
  --c-gray-0: #00000099;
  --c-gray-5: #f6f6f6;
  --c-gray-9: #d9d9d9;
  --c-gray-10: #d3d3d4;
  --c-gray-30: #a2a2a4;
  --c-gray-40: #69696D;
  --c-gray-50: #565659;
  --c-gray-90: #212121;

  --c-black-40: #171d1e;
  --c-black-95: #151515;

  --c-primary-5: #efe8ff;
  --c-primary-10: #d4c6fe;
  --c-primary-20: #b69ffe;
  --c-primary-30: #9576ff;
  --c-primary-40: #7856ff;
  --c-primary-45: #7756FF;
  --c-primary-50: #5535fd;
  --c-primary-60: #4531f7;
  --c-primary-70: #2629ee;
  --c-primary-80: #0023e9;
  --c-primary-90: #0017da;

  --c-error-5: #000;
  --c-error-8: #2d0001;
  --c-error-10: #410002;
  --c-error-20: #690005;
  --c-error-30: #93000a;
  --c-error-35: #b81f19;
  --c-error-40: #ba1a1a;
  --c-error-50: #de3730;
  --c-error-60: #ff5449;
  --c-error-70: #ff897d;
  --c-error-80: #ffb4ab;
  --c-error-90: #ffdad6;
  --c-error-95: #ffedea;

  --c-neutral-5: #0f120f;
  --c-neutral-10: #191c1b;
  --c-neutral-20: #2d3130;
  --c-neutral-30: #444746;
  --c-neutral-40: #5c5f5e;
  --c-neutral-50: #747876;
  --c-neutral-70: #a9acaa;
  --c-neutral-60: #8e9190;
  --c-neutral-80: #c4c7c5;
  --c-neutral-90: #fff;

  --c-neutral-variant-5: #000;
  --c-neutral-variant-10: #141d1b;
  --c-neutral-variant-15: #1C1B20;
  --c-neutral-variant-20: #293230;
  --c-neutral-variant-25: #f9fafc;
  --c-neutral-variant-30: #3f4946;
  --c-neutral-variant-40: #56605e;
  --c-neutral-variant-45: #605E5E;
  --c-neutral-variant-50: #6f7976;
  --c-neutral-variant-60: #899390;
  --c-neutral-variant-70: #a3adaa;
  --c-neutral-variant-80: #bec9c5;
  --c-neutral-variant-90: #dae5e1;

  --c-hint-40: #555555;
}
