@use "sass:meta";
@tailwind base;
@tailwind utilities;

.mdc-notched-outline__notch {
  @apply border-none;
}

@include meta.load-css("assets/styles/colors/variables.scss");
@include meta.load-css("assets/styles/reset");
@import "assets/styles/spacing.css";
@include meta.load-css("assets/styles/variables.scss");
@include meta.load-css("assets/styles/general");

@import "assets/styles/typography/font-faces.css";
@import "assets/styles/typography/headings.css";
@import "assets/styles/typography/bodies.css";

@include meta.load-css("assets/styles/colors/light_colors.scss");
@include meta.load-css("assets/styles/colors/material_theme.scss");
@import "assets/styles/colors/colors.css";

@include meta.load-css("assets/styles/components/index");

@include meta.load-css("assets/styles/page-layout");

@import "assets/fonts/fontawsome/css/all.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
